.thank-you-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.header {
  text-align: center;
  margin-bottom: 30px;
}

.thumbs-up {
  width: 60px;
  height: 60px;
  margin: 0 auto 20px;
  background-color: #4CAF50;
  border-radius: 50%;
  position: relative;
}

.thumbs-up::before {
  content: '👍';
  font-size: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.continue-shopping {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #8E44AD;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 30px;
}

.continue-shopping:hover {
  background-color: #732D91;
}

.info-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 30px;
}

.info-box {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 4px;
  text-align: right;
}

.info-box h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.unpaid {
  color: #4CAF50;
}

.order-summary {
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  text-align: right;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f5f5f5;
}

.total-summary {
  width: 50%;
  display: flex;
  justify-content: space-between;
  direction: rtl;
}

.grand-total {
  font-weight: bold;
}