*{
  padding: 0;
  margin: 0;
  font-family: "Almarai", sans-serif;

}
:root{
  --primary-color: #146590;
  --secondary-color: #0f4866;
  --borders-color: #176d9c4b;
  --text-color:white
}
body {
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 500px;
;
}


