.badges-container{
   width: 100%;
   text-align: center;
   margin-top: 20px;
   margin-bottom: 60px;
}
.badges-container .heading{
margin-bottom: 30px;
}
.badges-container .icons-container{
    display: flex;
    justify-content: space-around;
}
.badges-container .icons-container .single-icon-container path{
    fill: var(--primary-color);
}
.badges-container .icons-container .single-icon-container svg{
    width: 50px;
    height: 50px;
}
.badges-container .icons-container .single-icon-container{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 10px;
}