.App {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.bellow-product-image-container {
  padding: 0px 15px;
}
.heading {
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--primary-color);
  margin-bottom: 30px;
}
.announcement-bar {
  height: 50px;
  font-weight: 700;
  font-size: 1.2rem;
  background-color: var(--primary-color);
  color: var(--text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.cta-container {
  display: flex;
  justify-content: center;
}
.cta-container .cta {
  margin: 15px;
}
.prices {
  direction: rtl;
}
.main-price {
  direction: rtl;
  display: flex;
  gap: 10px;
  font-size: 1.9rem;
}
.compared-price {
  text-decoration: line-through;
  color: #999999 !important;
  margin-right: 5px;
  font-size: 1.9rem;
  display: flex;
  align-items: center;
}
.bundle-price-container {
  display: flex;
  direction: rtl;
  gap: 10px;
  justify-content: flex-start;
}
.prices-container {
  width: 100%;
  direction: rtl;
}
.cta {
  appearance: none;
  background-color: var(--primary-color);
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 15px;
  box-shadow: rgba(27, 31, 35, 0.1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  width: 80%;
  height: 50px;
  margin: 15px 0px;
  margin-bottom: 30px;
}

.cta:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.cta:hover {
  background-color: var(--secondary-color);
}

.cta:focus {
  box-shadow: rgba(46, 164, 79, 0.4) 0 0 0 3px;
  outline: none;
}

.cta:disabled {
  background-color: var(--primary-color);
  border-color: rgba(27, 31, 35, 0.1);
  color: rgba(255, 255, 255, 0.8);
  cursor: default;
}

.cta:active {
  background-color: var(--primary-color);
  box-shadow: rgba(20, 70, 32, 0.2) 0 1px 0 inset;
}
.image-container img {
  width: 100%;
  height: auto;
}
#testimonials {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  font-family: "Almarai", sans-serif;
  margin: 30px 0px;
}
.testimonial-heading {
  letter-spacing: 1px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #b3750b;
}

.testimonial-heading span {
  font-size: 1.3rem;
  color: #252525;
  margin-bottom: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.testimonial-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.testimonial-box {
  width: 500px;
  border: 2px solid var(--borders-color);
  border-radius: 10px;
  background-color: #ffffff;
  padding: 20px;
  margin: 15px;
  cursor: pointer;
}
.testimonial-image {
  border-radius: 10px;
  width: 400px;
  margin-top: 20px;
}
.profile-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
.profile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.profile {
  display: flex;
  align-items: center;
  gap: 8px;
}
.name-user {
  display: flex;
  flex-direction: column;
  text-align: right;
}
.name-user strong {
  color: #3d3d3d;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
}
.name-user span {
  color: #979797;
  font-size: 0.8rem;
}
.reviews {
  color: #f9d71c;
  display: inline-flex;
  gap: 3px;
  width: 80px;
  margin-left: 10px;
}
.reviews i {
  width: 20px;
}
.reviews-count {
  font-size: 14px;
  display: inline;
}
.reviews-overview-container {
  margin-top: 30px;
  direction: rtl;
}
.box-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.client-comment p {
  font-size: 0.9rem;
  color: #4b4b4b;
  text-align: right;
}
.testimonial-box:hover {
  transform: translateY(-10px);
  transition: all ease 0.3s;
}

@media (max-width: 790px) {
  .testimonial-box {
    width: 100%;
  }
  .testimonial-heading h1 {
    font-size: 1.4rem;
  }
}
@media (max-width: 340px) {
  .box-top {
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  .reviews {
    margin-top: 10px;
  }
}
.form-container {
  text-align: center;
}
.form-container form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  text-align: right;
}
.form-container form .cta {
  background-color: rgb(37, 157, 37);
  font-size: 1.5rem;
  width: 100%;
  height: 60px;
}
.form-container form .order-infos {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.form-container form .cta:hover {
  background-color: rgb(22, 112, 22);
}
/* input */
input[type="text"] {
  position: relative;
  cursor: text;
  font-size: 14px;
  line-height: 20px;
  height: 48px;
  background-color: #fff;
  border: 1.5px solid #d6d6e7;
  border-radius: 8px;
  color: rgb(35, 38, 59);
  overflow: hidden;
  transition: all 100ms ease-in-out;
  outline: none;
  text-indent: 16px;
  direction: rtl;
}
input[type="text"]:focus {
  border-color: var(--primary-color);
}

input[type="text"]::cursor {
  padding: 0 16px;
}
/* radio input */
.bundles-container {
  margin-top: 30px;
  width: 100%;
  margin-bottom: 35px;
  text-align: right;
}
.radio-group {
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 100%;
}

.custom-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 20px;
}

.custom-radio input {
  display: none;
}

/* Custom Radio Button Styling */
.radio-mark {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid #666;
  display: inline-block;
  position: relative;
  margin-left: 10px;
  transition: border-color 0.3s ease;
}

/* The colored dot inside the radio button */
.radio-mark::after {
  content: "";
  width: 12px;
  height: 12px;
  background-color: var(--primary-color);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

/* Checked state of the radio button */
.custom-radio input:checked + .radio-mark {
  border-color: var(--primary-color);
}

.custom-radio input:checked + .radio-mark::after {
  opacity: 1;
}

/* Hover effect */
.custom-radio:hover .radio-mark {
  border-color: var(--primary-color);
}
.custom-radio {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  border: 2px solid var(--borders-color);
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}
/* .custom-radio:active,.custom-radio:focus{
  border: 2px solid var(--primary-color);

} */
.bundle-option {
  direction: rtl;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.bundle-option .price {
  font-weight: 600;
}
.price {
  color: var(--primary-color);
  font-weight: 900;
  direction: rtl;
}
.bundle-price {
  color: var(--primary-color);
  font-weight: 900;
  direction: rtl;
}
.error-input {
  border: 1.5px solid red !important;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  text-align: right;
}
.input-container {
  display: flex;
  flex-direction: column;
}
.main-product-name h1 {
  direction: rtl;
  margin-top: 15px;
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 7 00;
}
